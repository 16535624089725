// 
// stepper.scss
// Vendor css override and new css added

.stepper-outline {

  .step-trigger:hover{
    background-color: transparent;
  }

  .bs-stepper-circle{
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background-color: rgba($primary, .1);
    border: 2px $border-style rgba($primary, .4);
    color:$primary;
    line-height: 1.9rem;
  }

  .bs-stepper-content {
    padding: 20px;
  }

  .step.active .bs-stepper-circle{
    background-color: $primary;
    color:$white;
    border: none;
    line-height: 2rem;
  }

  .step {
    .bs-stepper-label {
      margin: 0;
    }
    &.active .bs-stepper-label {
      color: $primary;
    }
  }
}

.step-trigger {
  padding: 5px;
  &:hover {
    text-decoration: none;
    background-color: transparent;
  }
}


@include media-breakpoint-down(md){
  .stepper-outline .bs-stepper-circle{
    width: 2.5rem;
    height: 2.5rem;
    line-height: 1.4rem;
  }
  .stepper-outline .step.active .bs-stepper-circle{
    line-height: 1.6rem;
  }
  .stepper-outline .bs-stepper-content {
    padding: 5px;
  }
}