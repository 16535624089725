// 
// reboot.scss
// Extended from Bootstrap
// 

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  z-index: 0;
  position: relative;
}

main {
  background-color: $body-bg;
}

header {
  position: relative;
  z-index: $zindex-header;
  background: $body-bg;
}

a {
  transition: $transition-base;
}

%heading > a{
  color: $headings-color;
  &:hover{
    color: $primary;
  }
}

img {
  max-width: 100%;
  height: auto;
}

video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

// Section padding top and bottom
section {
  padding-top: ($spacer * 3.5);
  padding-bottom: ($spacer * 2.8);
}

@include media-breakpoint-down(md) {
  section {
    padding-top: ($spacer * 4);
    padding-bottom: ($spacer * 3);
  }
}

// hr {
//   background-color: $light-gray !important;
// }