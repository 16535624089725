// 
// list-group.scss
// Extended from Bootstrap
// 


// 
// Additional style for theme
// 
.list-group .list-group-item i, .list-group .list-group-item span{
  margin-right: 5px;
}

// list group borderless
.list-group-borderless {
  .list-group-item {
    border: none !important;
    padding: 0.425rem 0;
  }
  a.list-group-item {
    &:hover {
      background-color: transparent;
      color:  $primary;
    }
  }
}

// List group dark 
.list-group-dark .list-group-item{
  color:$white;
  margin-bottom:10px;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  border-radius: $border-radius;
}
.list-group-dark .list-group-item:not(.bg-danger-soft-hover){
  &:hover, &.active {
    color: $dark !important;
    background-color:$white !important;
  }
}
