// 
// pagination.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// For better responsive
.pagination {
  .page-item{
    margin-bottom: 5px;
    float: left;
  }
}

//Pagination primary soft
.pagination-primary-soft{
  border: none;
  padding: 4px;
  border-radius: $border-radius;
  .page-item{
    margin: 4px;
  }
  .page-link{
    border: transparent;
    border-radius: $border-radius !important;
    color:$primary;
    background-color: rgba($primary, 0.1);
  }
  
}
.pagination-primary-soft .page-link:hover{
  z-index: 2;
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.pagination-primary-soft .page-item.active .page-link{
  color: $white;
  background-color: $primary;
}